import SiteWrapper from "../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../assets/images/pagerduty_dxable_og.png";

import "./free-trial-thanks@gen.scss";

const GeneratedFreeTrialThanks = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          PagerDuty無料トライアル申し込み完了 - PagerDuty正規代理店 -
          株式会社Digital Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="PagerDutyの無料トライアルのご依頼を受け付けました。後ほど担当者よりご連絡いたします。"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="PagerDutyの無料トライアルのご依頼を受け付けました。後ほど担当者よりご連絡いたします。"
        />

        <meta
          property="og:title"
          content="PagerDuty無料トライアル申し込み完了 - PagerDuty正規代理店 - 株式会社Digital Stacks"
        />

        <meta
          property="og:description"
          content="PagerDutyの無料トライアルのご依頼を受け付けました。後ほど担当者よりご連絡いたします。"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/free-trial-thanks/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/free-trial-thanks/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <div className="thanks-wrapper">
        <div className="relative mb-24 banner-container banner-container-green-bg">
          <p className="banner-para">
            PagerDutyの無料トライアルのご依頼を受け付けました。
            <br />
            後ほど担当者よりご連絡いたします。
          </p>
          <div className="absolute top-[97%] left-[50%] -translate-x-[50%]">
            <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/triangle_Green_e011b082b8.png" />
          </div>
        </div>
        <div className="my-24 center-2 ">
          <h2 className="mb-4 center-title">PagerDuty おすすめコンテンツ</h2>
          <div className="flex items-center gap-4">
            <button className="thank-you-cta ">
              <a
                className="thank-you-cta-link"
                href="https://pagerduty.digitalstacks.net/faq/"
              >
                PagerDuty FAQ
              </a>
            </button>
            <button className=" thank-you-cta">
              <a
                className="thank-you-cta-link"
                href="https://pagerduty.digitalstacks.net/casestudy/"
              >
                PagerDuty導入事例
              </a>
            </button>
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedFreeTrialThanks;
